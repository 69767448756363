// import api from '.';

export const login = async (payload) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const urlencoded = new URLSearchParams();
  urlencoded.append('username', payload.username);
  urlencoded.append('password', payload.password);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/auth/login`,
    requestOptions
  );
  return await response.json();
};

export const verifyToken = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/auth/verify`,
    requestOptions
  );

  if (response.status === 401) {
    return null;
  }

  return await response.json();
};
