import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import BaseLayout from '../components/BaseLayout';
import UserPage from '../pages/UserPage';
import KoreanPage from '../pages/KoreanPage';
import KoreanRegisterPage from '../pages/KoreanRegisterPage';
import UserRegisterPage from '../pages/UserRegisterPage';
import QuestionPage from '../pages/QuestionPage';
import QuestionRegisterPage from '../pages/QuestionRegisterPage';
import useAuthStore from '../store/useAuth';

const PrivateRoute = ({ Component }) => {
  const checkToken = useAuthStore((state) => state.checkToken);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const logout = useAuthStore((state) => state.logout);

  const navigate = useNavigate();

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      logout();
      navigate('/');
    }
  }, [isAuthenticated, logout, navigate]);

  return (
    <BaseLayout>
      <Component />
    </BaseLayout>
  );
};

const Router = () => {
  const privateRoutes = [
    { path: '/korean', Component: KoreanPage },
    { path: '/korean/register', Component: KoreanRegisterPage },
    { path: '/korean/:id', Component: KoreanRegisterPage },
    { path: '/question', Component: QuestionPage },
    { path: '/question/register', Component: QuestionRegisterPage },
    { path: '/user', Component: UserPage },
    { path: '/user/register', Component: UserRegisterPage },
  ];

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        {privateRoutes.map(({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={<PrivateRoute Component={Component} />}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
