import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { login, verifyToken } from '../api/auth';

const useAuthStore = create(
  persist(
    (set, get) => ({
      user: null,
      token: null,
      isAuthenticated: false,
      error: null,

      checkToken: async (token) => {
        const storedToken = token || get().token;
        try {
          const response = await verifyToken(storedToken);
          set({
            isAuthenticated: true,
            user: response.user,
            token: storedToken,
          });
        } catch (error) {
          set({
            isAuthenticated: false,
            user: null,
            token: null,
            error: error.message,
          });
        }
      },

      login: async (credentials) => {
        try {
          const response = await login(credentials);
          if (response.statusCode >= 400) {
            throw new Error(response.message);
          } else {
            set({
              isAuthenticated: true,
              user: {
                username: credentials.username,
              },
              token: response.access_token,
              error: null,
            });
          }
        } catch (error) {
          set({
            isAuthenticated: false,
            user: null,
            token: null,
            error: error.message,
          });
        }
      },

      logout: () => {
        set({ isAuthenticated: false, user: null, token: null, error: null });
      },
    }),
    {
      name: 'auth-storage', // unique name
      getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useAuthStore;
